<div class="bordered">
  <table>
    <thead class="sticky">
      <tr>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_parcel">
          <sort-by
            *ngIf="sortableColumns.occupation_parcel"
            [selected]="'occupation_parcel' === sortOrder.sortBy"
            (sort)="onSortParcel('occupation_parcel')"
            [sortDir]="sortOrder.direction">
            Parcela
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_parcel">
            Parcela
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_parcel_title">
          <sort-by
            *ngIf="sortableColumns.occupation_parcel_title"
            [selected]="'occupation_parcel_title' === sortOrder.sortBy"
            (sort)="onSortParcel('occupation_parcel_title')"
            [sortDir]="sortOrder.direction">
            Parcela
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_parcel_title">
            Parcela
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_title">
          <sort-by
            *ngIf="sortableColumns.occupation_title"
            [selected]="'occupation_title' === sortOrder.sortBy"
            (sort)="onSort('occupation_title','parcel.title.lv')"
            [sortDir]="sortOrder.direction">
            LV
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_title">
            LV
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_area">
          <sort-by
            *ngIf="sortableColumns.occupation_area"
            [selected]="'occupation_area' === sortOrder.sortBy"
            (sort)="onSort('occupation_area','parcel.katuzeNazev')"
            [sortDir]="sortOrder.direction">
            K.Ú.
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_area">
            K.Ú.
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_concern_object">{{'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT' | wordtranslate:true}}</th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_phase">
          <sort-by
            *ngIf="sortableColumns.occupation_phase"
            [selected]="'occupation_phase' === sortOrder.sortBy"
            (sort)="onSort('occupation_phase','occupations[0].zabst')"
            [sortDir]="sortOrder.direction">
            {{'ETAPA_USEK' | wordtranslate}}
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_phase">
            {{'ETAPA_USEK' | wordtranslate}}
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_occupation">
          <sort-by
            *ngIf="sortableColumns.occupation_occupation"
            [selected]="'occupation_occupation' === sortOrder.sortBy"
            (sort)="onSort('occupation_occupation','occupations[0].zabcis')"
            [sortDir]="sortOrder.direction">
            Zábor
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_occupation">
            Zábor
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_cases">
          <sort-by
            *ngIf="sortableColumns.occupation_cases"
            [selected]="'occupation_cases' === sortOrder.sortBy"
            (sort)="onSort('occupation_cases', 'countCases')"
            [sortDir]="sortOrder.direction">
            Případy
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_cases">
            Případy
          </p>
        </th>
        <th [attr.colspan]="occupationPriceColumnsCount" *ngIf="occupationPriceColumnsCount > 0" class="bordered-bottom">Cena [<span class="unit">Kč</span>]</th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_assessment_price">Původ ceny</th>
      </tr>
      <tr>
        <th *ngIf="visibleColumns.occupation_price_land_price">Za 1 <span class="unit">m<sup>2</sup></span></th>
        <th *ngIf="visibleColumns.occupation_price_rent_price">Celkem za zábor</th>
        <th *ngIf="visibleColumns.occupation_price_rent_price_year">Celkem za rok</th>
        <th *ngIf="visibleColumns.occupation_price_rent_length">Doba nájmu</th>
        <th *ngIf="visibleColumns.occupation_price_total_rental_price">Celkem za dobu nájmu</th>
        <th *ngIf="visibleColumns.occupation_price_summary_cases">Celkem</th>
      </tr>
    </thead>
    <tbody *ngFor="let itemOccupation of data" class="hover">
      <ng-container *ngIf="itemOccupation.parcel; let parcel">
        <tr *ngIf="visibleColumns.label_gp && parcel.knBudouc">
          <th [attr.colspan]="occupationColumnsCount" class="bordered-top bordered-bottom">
            <a *ngIf="parcel.knBudouc.knGp.geometricPlanRequest && geometricPlanModuleEnabled; else withoutLink"
               uiSref="symap.project.geometricPlanRequests.detail"
               [uiParams]="{ id: parcel.knBudouc.knGp.geometricPlanRequest.id, tab: 'parcels-budouc' }"
            >GP č. {{ parcel.knBudouc.knGp.cis }}</a>
            <ng-template #withoutLink>GP č. {{ parcel.knBudouc.knGp.cis }}</ng-template>
          </th>
        </tr>
        <tr>
          <td *ngIf="visibleColumns.occupation_parcel" class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="parcel"
              [occupation]="itemOccupation.occupations[0]"
              [updateOccupationParcelPath]="updateOccupationParcelPath"
              [tooltipType]="'parcel'"
              [tooltipData]="{parcel: parcel}"
              [showProtection]="true"
            ></cell-parcel>
          </td>
          <td *ngIf="visibleColumns.occupation_parcel_title" class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="parcel"
              [tooltipType]="'parcel'"
              [tooltipData]="{parcel: parcel, withoutTitle: true}"
              [showProtection]="true"
            ></cell-parcel>
          </td>
          <td *ngIf="visibleColumns.occupation_title" class="number">
            <a *ngIf="parcel.title?.id" uiSref="symap.project.titles.detail" [uiParams]="{id: parcel.title.id, tab: null}">
              <b>{{parcel.title.lv}}</b>
            </a>
          </td>
          <td *ngIf="visibleColumns.occupation_area">
            {{parcel.katuzeNazev}}
          </td>
          <td *ngIf="visibleColumns.occupation_concern_object">
            <gmt-construction-object-list [constructionObjects]="itemOccupation.occupations[0].constructionObjects"></gmt-construction-object-list>
          </td>
          <td *ngIf="visibleColumns.occupation_phase" class="no-padding cell-max-height">
            <cell-occupation
              [occupation]="itemOccupation.occupations[0]"
              [type]="'phase'"
            ></cell-occupation>
          </td>
          <td *ngIf="visibleColumns.occupation_occupation" class="no-padding cell-max-height">
            <cell-occupation
              [occupation]="itemOccupation.occupations[0]"
              [showSwitch]="visibleColumns.occupation_switch"
              [showUpdate]="visibleColumns.update_occupation"
            ></cell-occupation>
          </td>
          <td *ngIf="visibleColumns.occupation_cases" class="no-padding cell-max-height">
            <cell-cases
              [casesCount]="itemOccupation.occupations[0].countCases"
              [tooltipType]="'cases'"
              [tooltipData]="{filter: {filters: {occupationId: itemOccupation.occupations[0].id, cancelStatus: 'notCancelled', loadCollections: ['caseOwnerships']}}}"
            ></cell-cases>
          </td>
          <td *ngIf="visibleColumns.occupation_price_land_price" class="number">
            <ng-container *ngIf="itemOccupation.occupations[0].rentalPriceType && !itemOccupation.occupations[0].priceAssessmentLandPrice">-</ng-container>
            {{itemOccupation.occupations[0].priceAssessmentLandPrice | number | price}}
          </td>
          <td *ngIf="visibleColumns.occupation_price_rent_price" class="number">
            {{itemOccupation.occupations[0].yearRentPrice | number | price}}
          </td>
          <td *ngIf="visibleColumns.occupation_price_rent_price_year" class="number">
            <ng-container *ngIf="itemOccupation.occupations[0].rentalPriceType && !itemOccupation.occupations[0].yearRentPrice">-</ng-container>
            {{itemOccupation.occupations[0].yearRentPrice | number | price}}
          </td>
          <td *ngIf="visibleColumns.occupation_price_rent_length" class="number">
            {{itemOccupation.occupations[0].rentalLength | number}}
          </td>
          <td *ngIf="visibleColumns.occupation_price_total_rental_price" class="number">
            {{itemOccupation.occupations[0].totalRentPrice | number | price}}
          </td>
          <td *ngIf="visibleColumns.occupation_price_summary_cases" class="no-padding cell-max-height">
            <cell-price *ngIf="itemOccupation.occupations[0].caseParcel"
              [price]="(itemOccupation.occupations[0].caseOccupation.landPrice + (itemOccupation.occupations[0].caseOccupation.compensationPrice ? itemOccupation.occupations[0].caseOccupation.compensationPrice : 0))"
              [editable]="canEdit('admin,update_case_price') && itemOccupation.occupations[0].caseOccupation.landPrice !== undefined"
              [editFunction]="priceEditFunction"
              [editFunctionParams]="{parcelId: itemOccupation.occupations[0].caseParcel.parcel.id, occupationId: itemOccupation.occupations[0].id}"
              [casePrice]="itemOccupation.occupations[0].caseParcel"
            ></cell-price>
          </td>
          <td *ngIf="visibleColumns.occupation_assessment_price" class="number">
            <ng-container *ngIf="itemOccupation.occupations[0].rentalPriceType === 'F'">Cenový výměr MF {{itemOccupation.occupations[0].priceAssessmentYear}}</ng-container>
            <ng-container *ngIf="itemOccupation.occupations[0].rentalPriceType === 'E'">Znalecký posudek č. {{itemOccupation.occupations[0].rentalExpertOpinionNumber}}, {{itemOccupation.occupations[0].rentalExpertOpinionDate | date}}, {{itemOccupation.occupations[0].rentalExpert | subjectName}}</ng-container>
            <ng-container *ngIf="itemOccupation.occupations[0].rentalPriceType === 'O'">{{itemOccupation.occupations[0].rentalOtherPriceSource}}</ng-container>
          </td>
        </tr>
        <tr *ngIf="parcel.knBudouc && currentColumnsCount > 0" class="bordered-top">
          <th *ngIf="visibleColumns.current_title">
            LV
          </th>
          <th *ngIf="visibleColumns.current_parcel">
            Původní parcela
          </th>
          <th *ngIf="visibleColumns.current_parcel_title">
            Původní parcela
          </th>
          <th *ngIf="visibleColumns.current_protection">
            Ochrana
          </th>
          <th *ngIf="visibleColumns.current_concern_object">
            {{'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT' | wordtranslate:true}}
          </th>
          <th [attr.colspan]="occupationColumnsCount - currentColumnsCount" class="bordered-bottom">
          </th>
        </tr>
        <tr *ngFor="let currentParcel of parcel.knBudouc?.parcels; index as i" class="current-parcel" [ngClass]="{first: i === 0}">
          <td *ngIf="visibleColumns.current_title" class="number">
            <a *ngIf="currentParcel.title?.id" uiSref="symap.project.titles.detail" [uiParams]="{id: currentParcel.title.id, tab: null}">
              <b>{{currentParcel.title.lv}}</b>
            </a>
          </td>
          <td *ngIf="visibleColumns.current_parcel" class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="currentParcel"
              [tooltipType]="'parcel'"
              [tooltipData]="{parcel: currentParcel}"
            ></cell-parcel>
          </td>
          <td *ngIf="visibleColumns.current_parcel_title" class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="currentParcel"
              [tooltipType]="'parcel'"
              [tooltipData]="{parcel: currentParcel, withoutTitle: true}"
            ></cell-parcel>
          </td>
          <td *ngIf="visibleColumns.current_protection">
            <div>
              {{currentParcel | parcelprotection}}
            </div>
            <div *ngIf="currentParcel.bpejList.length > 0" class="comma-list">
              (<span *ngFor="let itemBpej of currentParcel.bpejList">{{itemBpej.bpejKod}}</span>)
            </div>
          </td>
          <td *ngIf="visibleColumns.current_concern_object">
            {{currentParcel.constructionObjectsText}}
          </td>
          <td [attr.colspan]="occupationColumnsCount - currentColumnsCount">
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
