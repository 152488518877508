<div class="mb-10" *ngIf="data.geometricPlansSelected.length > 1">
  <gmt-button [gmtDisabled]="geometricPlanIndex === 0" [clickAction]="previous">Předchozí GP</gmt-button>
  <span class="pl-20 pr-20"><strong>{{geometricPlanIndex + 1}} / {{data.geometricPlansSelected.length}}</strong></span>
  <gmt-button [gmtDisabled]="geometricPlanIndex + 1 === data.geometricPlansSelected.length" [clickAction]="next">Následující GP</gmt-button>
</div>

<h2>{{data.geometricPlansSelected[geometricPlanIndex].kutx ? data.geometricPlansSelected[geometricPlanIndex].kutx + ' - ' : ''}}{{data.geometricPlansSelected[geometricPlanIndex].cis}}</h2>
<div [loading]="easementPageableList.list.loading || loading">
  <div *ngIf="easementPageableList.list.list !== null && !loading">
    <div class="table-top-container" *ngIf="easementPageableList.list.itemCount">
      <div class="flex1" style="line-height:25px;flex-basis:0;">{{getSelectedCount() > 1 && getSelectedCount() < 5 ? 'Zvolena' : 'Zvoleno'}} <b>{{getSelectedCount()}}</b> {{getSelectedCount() === 1 ? 'věcné břemeno' : (getSelectedCount() < 5 && getSelectedCount() !== 0 ? 'věcná břemena' : 'věcných břemen')}} z aktuáního geometrického plánu</div>
      <pagescounter [list]="easementPageableList.list" (callbackRegister)="easementPageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="flex1"></div>
    </div>
    <div class="empty-info" *ngIf="!easementPageableList.list.itemCount">
      Žádná věcná břemena s oprávněním
    </div>
    <div class="bordered" *ngIf="easementPageableList.list.itemCount">
      <table>
        <thead>
          <tr>
            <th>
              <a (click)="checkAll()">
                <gmt-checkbox gmtType="multiselect" [gmtChecked]="data.geometricPlansSelected[geometricPlanIndex].checkedAll"></gmt-checkbox>
              </a>
            </th>
            <th>Parcela</th>
            <th>Případ</th>
            <th>LV</th>
            <th>Plocha VB [<span class="unit">m<sup>2</sup></span>]</th>
            <th>Délka VB [<span class="unit">m</span>]</th>
            <th>Poloha VB</th>
            <th>Vlastník</th>
            <th *ngIf="!easementPriceByShare">Cena [<span class="unit">Kč</span>]</th>
          </tr>
        </thead>
        <tbody class="hover" *ngFor="let easement of easementPageableList.list.list; let $indexGroup = index">
          <tr (click)="!isDisabled(easement) ? toggleSelectionEasement(easement) : null">
            <td class="text-center">
              <gmt-checkbox gmtType="multiselect" [gmtChecked]="isChecked(easement)" [gmtDisabled]="isDisabled(easement)"></gmt-checkbox>
            </td>
            <td class="no-padding cell-max-height">
              <div class="fill-content">
                <cell-parcel [parcel]="easement.parcel"
                             [tooltipType]="'parcel'"
                             [tooltipData]="{parcel: easement.parcel}"></cell-parcel>
              </div>
            </td>
            <td class="no-padding">
              <div class="fill-content">
                <cell-cases [casesCount]="easement.parcel.countCases"
                            [tooltipType]="'cases'"
                            [tooltipData]="{filter: {filters: {parcelId: easement.parcel.id, cancelStatus: 'notCancelled'}}}"></cell-cases>
              </div>
            </td>
            <td class="number">
              {{easement.parcel.title ? easement.parcel.title.lv : '-'}}
            </td>
            <td class="number">
              {{easement.vymera}}
            </td>

            <td class="number">
              {{easement.delka}}
            </td>
            <td class="text-center">
              {{easement|easementOccupation}}
              <div *ngIf="isPermanentOccupationOnParcel(easement.parcel)" class="mt-5 text-center"><i class="fa fa-info-circle"></i> Na parcele se nachází trvalý zábor</div>
            </td>
            <td class="no-padding">
              <div class="fill-content">
                <cell-ownership *ngIf="easement.parcel.title" [count]="easement.parcel.title.countOwnerships"
                                [ownerships]="easement.parcel.title.ownerships"
                                [tooltipData]="{filter: {sortOrder: {sortBy: 'subjectName'},filters: {titleId: easement.parcel.title.id, validity: 'valid'}}, attributes: {subject: {load: false}}}"
                                [tooltipType]="'ownership-list'"></cell-ownership>
              </div>
            </td>
            <td class="number" *ngIf="!easementPriceByShare">
              {{easement.easementPrice|number}}
            </td>
          </tr>
          <tr class="bordered-top error-message" *ngIf="isDisabled(easement)" [ngClass]="{'bordered-bottom': $indexGroup < easementPageableList.list.list.length - 1}">
            <td [attr.colspan]="!easementPriceByShare ? 8 : 7"><div *ngFor="let msg of getDisabledReason(easement)">{{msg}}</div></td>
          </tr>
        </tbody>
      </table>
    </div>
    <itemscounter *ngIf="easementPageableList.list.itemCount" [list]="easementPageableList.list" (callbackRegister)="easementPageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
    <pagination *ngIf="easementPageableList.list.itemCount" [shouldUpdateUrl]="false" (pageChanged)="pageChanged($event)" [list]="easementPageableList.list" (callbackRegister)="easementPageableList.paginationCallbackRegister($event)"></pagination>
  </div>
</div>

<div class="mt-10" *ngIf="data.geometricPlansSelected.length > 1">
  <gmt-button [gmtDisabled]="geometricPlanIndex === 0" [clickAction]="previous">Předchozí GP</gmt-button>
  <span class="pl-20 pr-20" *ngIf="geometricPlanIndex < data.geometricPlansSelected.length"><strong>{{geometricPlanIndex + 1}} / {{data.geometricPlansSelected.length}}</strong></span>
  <gmt-button [gmtDisabled]="geometricPlanIndex + 1=== data.geometricPlansSelected.length" [clickAction]="next">Následující GP</gmt-button>
</div>

<div class="mt-20">{{getTotalSelected() > 1 && getTotalSelected() < 5 ? 'Zvolena' : 'Zvoleno'}} celkem <b>{{getTotalSelected()}}</b> {{getTotalSelected() === 1 ? 'věcné břemeno' : (getTotalSelected() < 5 && getTotalSelected() !== 0 ? 'věcná břemena' : 'věcných břemen')}}</div>

<div class="mt-10">
  <gmt-button [clickAction]="submit" [gmtDisabled]="!getTotalSelected()">Další</gmt-button>
</div>
