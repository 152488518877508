<div class="detail" [loading]="loading">
  <div *ngIf="!loading">

    <div class="row">
      <div class="col-6">
        <a uiSref="symap.project.titles.detail" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Zpět</a>
      </div>
    </div>

    <h1>Znalecké posudky<span>LV{{ title.lv }}</span></h1>
    <div *ngIf="parcelsWithPermanentOccupation?.length || buildings?.length">
      <h2 class="mt-30"><strong>Výkupy</strong> - trvalé zábory</h2>
      <table class="table bordered" *ngIf="permanentOpinions.length > 0">
        <thead>
        <tr>
          <th rowspan="2" class="cell">Původ ceny</th>
          <th rowspan="2" class="cell">Dotčené nemovitosti</th>
          <th colspan="4" class="bordered-bottom">Cena</th>
        </tr>
        <tr>
          <th class="cell">Pozemek</th>
          <th class="cell">Porost<br/>(vedlejší úpravy)</th>
          <th class="cell">Stavba</th>
          <th class="cell">Celkem<span *ngIf="hasExpertOpinionCoefficient"><br/>vč. koeficientu dle zák. č.&nbsp;416/2008</span></th>
        </tr>
        </thead>
        <tbody>
        <tr class="clickable" *ngFor="let opinion of permanentOpinions" (click)="onUpdateOpinion(opinion)">
          <!-- původ ceny -->
          <td class="cell">
            <strong>{{ opinion.priceType | priceTypeName }}</strong><br/>

            <!-- znalecký posudek -->
            <span *ngIf="opinion.priceType === 'E'">
              č. {{ opinion.expertOpinionNumber }} ze dne {{ opinion.expertOpinionDate | date }}<br/>
              vyhotovil {{ opinion.expert | subjectName }}
            </span>

            <!-- jiné určení -->
            <span *ngIf="opinion.priceType === 'O'">{{ opinion.otherSource }}<br/>
              <span *ngIf="opinion.otherOwnership">{{ opinion.otherOwnership | ownershipName }}</span>
              <span *ngIf="!opinion.otherOwnership">pro celé LV</span>
            </span>

            <!-- předběžný posudek -->
            <span *ngIf="opinion.priceType === 'P'">č. {{ opinion.preliminaryOpinionNumber }}</span>
          </td>

          <!-- dotčené nemovitosti -->
          <td class="cell no-padding">
            <div class="fill-content">
              <div [tooltip]="tooltipComponent"
                   [activeContent]="true"
                   [data]="{ filter: { filters: { opinionId: opinion.id, validity: 'valid' }, limit: null }, customData: { priceType: opinion.priceType }}"
                   class="tooltip-hover-area fill-content"
              >
                <div class="fill-content flexbox-container flex-centered padding-10">
                  <div class="icon-count flexbox-container">
                    <i class="fa fa-toggle-up"></i>
                  </div>
                  <div class="number ml-4">{{ opinion.countParcelPrices }}</div>
                </div>
              </div>
            </div>
          </td>

          <!-- pozemek -->
          <td class="cell number">{{ opinion.landPrice | number | price }}</td>
          <!-- porost (vedlejší úpravy) -->
          <td class="cell number">{{ opinion.vegetationPrice | number | price }}</td>
          <!-- stavba -->
          <td class="cell number">{{ opinion.buildingsPrice | number | price }}</td>
          <!-- celkem vč. koeficientu -->
          <td class="cell number">
            {{ ((opinion.landPrice || 0) + (opinion.vegetationPrice || 0) + (opinion.buildingsPrice || 0)) | number | price }}
          </td>
        </tr>
        </tbody>
      </table>

      <div class="empty-info pt-20 pb-20 bordered centered-text" *ngIf="!permanentOpinions.length">
        Nebyly nalezeny žádné posudky ani jiná určení ceny.
      </div>

      <div class="mt-10 text-center" *ngIf="isAllowedToCreateOpinion">
        <a class="button m-10" uiSref="symap.project.titles.detail.pricing.expertOpinion" [uiParams]="{ occupationType: 'P' }" *ngIf="hasAvailableBuildings || hasAvailableParcelsWithPermanentOccupationWithoutOpinion">
          Vytvořit znalecký posudek
        </a>
        <a class="button m-10" uiSref="symap.project.titles.detail.pricing.otherSource" [uiParams]="{ occupationType: 'P' }" *ngIf="hasAvailableBuildings || hasAvailableParcelsWithPermanentOccupation">
          Vytvořit cenu podle jiného zdroje
        </a>
      </div>
    </div>

    <!-- posudky pro náhrady za trvalé porosty -->
    <div *ngIf="parcelsWithTemporaryOccupation?.length">
      <h2 class="mt-30"><strong>Nájmy</strong> - náhrady za trvalé porosty</h2>
      <table class="table bordered" *ngIf="temporaryOpinions.length > 0">
        <thead>
        <tr>
          <th rowspan="2" class="cell">Původ ceny</th>
          <th rowspan="2" class="cell">Dotčené nemovitosti</th>
          <th colspan="2" class="bordered-bottom">Cena</th>
        </tr>
        <tr>
          <th class="cell">Náhrada za trvalý porost</th>
          <th class="cell">Celkem<br/>vč. koeficientu dle zák. č.&nbsp;416/2008</th>
        </tr>
        </thead>
        <tbody>
        <tr class="clickable" *ngFor="let opinion of temporaryOpinions" (click)="onUpdateOpinion(opinion)">
          <!-- původ ceny -->
          <td class="cell">
            <strong>{{ opinion.priceType | priceTypeName }}</strong><br/>

            <!-- znalecký posudek -->
            <span *ngIf="opinion.priceType === 'E'">
              č. {{ opinion.expertOpinionNumber }} ze dne {{ opinion.expertOpinionDate | date }}<br/>
              vyhotovil {{ opinion.expert | subjectName }}
            </span>

            <!-- jiné určení -->
            <span *ngIf="opinion.priceType === 'O'">{{ opinion.otherSource }}<br/>
              <span *ngIf="opinion.otherOwnership">pro {{ opinion.otherOwnership | ownershipName }}</span>
              <span *ngIf="!opinion.otherOwnership">pro celé LV</span>
            </span>

            <!-- předběžný posudek -->
            <span *ngIf="opinion.priceType === 'P'">č. {{ opinion.preliminaryOpinionNumber }}</span>
          </td>

          <!-- dotčené nemovitosti -->
          <td class="cell no-padding">
            <div class="fill-content">
              <div [tooltip]="tooltipComponent"
                   [activeContent]="true"
                   [data]="{ filter: { filters: { opinionId: opinion.id, validity: 'valid' }, limit: null }, customData: { priceType: opinion.priceType }}"
                   class="tooltip-hover-area fill-content"
              >
                <div class="fill-content flexbox-container flex-centered padding-10">
                  <div class="icon-count flexbox-container">
                    <i class="fa fa-toggle-up"></i>
                  </div>
                  <div class="number ml-4">{{ opinion.countParcelPrices }}</div>
                </div>
              </div>
            </div>
          </td>

          <!-- náhrada za trvalý porost -->
          <td class="cell number">{{ opinion.compensationPrice | number | price }}</td>
          <!-- celkem -->
          <td class="cell number">{{ opinion.compensationPrice | number | price }}</td>
        </tr>
        </tbody>
      </table>
      <div class="empty-info pb-20 pt-20 bordered centered-text" *ngIf="!temporaryOpinions.length">
        Nebyly nalezeny žádné posudky ani jiná určení ceny.
      </div>

      <div class="mt-10 text-center" *ngIf="isAllowedToCreateOpinion && hasAvailableParcelsWithTemporaryOccupation">
        <a class="button m-10" uiSref="symap.project.titles.detail.pricing.expertOpinion" [uiParams]="{ occupationType: 'T' }">Vytvořit znalecký posudek</a>
        <a class="button m-10" uiSref="symap.project.titles.detail.pricing.otherSource" [uiParams]="{ occupationType: 'T' }">Vytvořit cenu podle jiného zdroje</a>
      </div>
    </div>
  </div>

  <help [helpId]="helpIds.TITLE_DETAIL_OPINIONS" class="cm-help-page"></help>
</div>

